import { HttpClientModule } from '@angular/common/http';
import { ApplicationRef, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NavigationStart,
  Router,
  RouterModule,
  TitleStrategy,
} from '@angular/router';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { MsalModule } from '@azure/msal-angular';
import {
  BrowserUtils,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { createErrorHandler, TraceService } from '@sentry/angular';
import {
  concat,
  concatMap,
  filter,
  first,
  interval,
  map,
  of,
  switchMap,
} from 'rxjs';

import { AccountDomainModule } from '@consalio/account/domain';
import { AuthDomainModule, IsLoggedInGuard } from '@consalio/auth/domain';
import { TabsModule } from '@consalio/shared/tabs';
import { ToastsModule, ToastsService } from '@consalio/shared/toasts';
import { TooltipModule } from '@consalio/shared/tooltip';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppEffects } from './app.effects';
import { ConsalioTitleStrategy } from './consalio-title-strategy.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    TooltipModule,
    TabsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    RouterModule.forRoot(
      [
        {
          path: 'auth',
          loadChildren: () =>
            import('@consalio/auth/shell').then(
              (module) => module.AuthShellModule
            ),
        },
        {
          path: '',
          canActivate: [
            IsLoggedInGuard,
            //  UserAccountGuard
          ],
          loadChildren: () =>
            import('./layout/layout.module').then(
              (module) => module.LayoutModule
            ),
        },
        // {
        //   path: 'terms-of-use',
        //   loadChildren: () =>
        //     import('./terms-of-use/terms-of-use.module').then(
        //       (m) => m.TermsOfUseModule
        //     ),
        // },
        // {
        //   path: 'privacy-policy',
        //   loadChildren: () =>
        //     import('./privacy-policy/privacy-policy.module').then(
        //       (m) => m.PrivacyPolicyModule
        //     ),
        // },
        {
          path: 'login',
          redirectTo: 'auth',
        },
        {
          path: 'login/account-activation',
          redirectTo: 'auth/activate-account',
        },
        {
          path: 'login/reset-password',
          redirectTo: 'auth/reset-password',
        },
        {
          path: 'privacy-policy',
          loadChildren: () =>
            import('./privacy-policy/privacy-policy.module').then(
              (m) => m.PrivacyPolicyModule
            ),
        },
        {
          path: 'terms-of-use',
          loadChildren: () =>
            import('./terms-of-use/terms-of-use.module').then(
              (m) => m.TermsOfUseModule
            ),
        },
        {
          path: '**',
          redirectTo: 'dashboard',
        },
      ],
      {
        initialNavigation:
          typeof window !== 'undefined' &&
          !BrowserUtils.isInIframe() &&
          !BrowserUtils.isInPopup()
            ? 'enabledNonBlocking'
            : typeof window === 'undefined'
            ? 'enabledNonBlocking'
            : 'disabled',
        relativeLinkResolution: 'corrected',
      }
    ),
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {}
    ),
    EffectsModule.forRoot([AppEffects]),
    StoreRouterConnectingModule.forRoot(),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
          serialize: true,
        }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    AccountDomainModule,
    AuthDomainModule,
    ToastsModule,
    NgxMatomoTrackerModule.forRoot({
      trackerUrl: environment.matomo.trackerUrl,
      siteId: environment.matomo.siteId,
      enableJSErrorTracking: true,
      acceptDoNotTrack: true,
      disabled: !environment.production,
    }),
    NgxMatomoRouterModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: 'f9f3d8c6-ece3-4078-8ee3-dcf9eb59cd08', // Application (client) ID from the app registration
          authority: 'https://login.microsoftonline.com/common', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          redirectUri: environment.redirectUri, // This is your redirect URI
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
        },
      }),
      {
        interactionType: InteractionType.Popup, // MSAL Guard Configuration
        authRequest: {
          scopes: ['user.read'],
        },
      },
      {
        interactionType: InteractionType.Popup, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ]),
      }
    ),
  ],
  providers: [
    {
      provide: TitleStrategy,
      useClass: ConsalioTitleStrategy,
    },
    environment.production
      ? {
          provide: ErrorHandler,
          useValue: createErrorHandler({
            showDialog: true,
          }),
        }
      : [],
    {
      provide: TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    applicationRef: ApplicationRef,
    swUpdate: SwUpdate,
    router: Router,
    toastsService: ToastsService,
    _trace: TraceService
  ) {
    if (swUpdate.isEnabled) {
      const appIsStable$ = applicationRef.isStable.pipe(
        first((isStable) => isStable === true)
      );
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(
        appIsStable$,
        everySixHours$
      );

      everySixHoursOnceAppIsStable$.subscribe(() => {
        swUpdate.checkForUpdate();
      });
      swUpdate.available.subscribe(() => {
        swUpdate.activateUpdate();
      });
      swUpdate.activated
        .pipe(
          switchMap(() => {
            return toastsService
              .open(
                'There is a new version available. Do you want to reload?',
                'info',
                'Reload'
              )
              .afterDismissed()
              .pipe(
                concatMap((toastDismissal) => {
                  if (toastDismissal.dismissedByAction) {
                    return of(location.href);
                  }

                  return router.events.pipe(
                    filter(
                      (event): event is NavigationStart =>
                        event instanceof NavigationStart
                    ),
                    map((event) => event.url)
                  );
                })
              );
          })
        )
        .subscribe((url) => {
          location.href = url;
        });

      swUpdate.unrecoverable.subscribe(() => location.reload());
    }
  }
}
