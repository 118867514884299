import { HttpParams } from '@angular/common/http';
import { JsonPrimitive, ReadonlyDeep } from 'type-fest';

import { SortOrder } from './sorting';

export type JsonPrimitiveRecord = Record<
  string,
  JsonPrimitive | JsonPrimitive[] | undefined
>;

export interface QueryParamsInterface<
  Filters extends Record<string, JsonPrimitiveRecord> | JsonPrimitiveRecord
> {
  page: number;
  pageSize: number;
  sortBy?: string;
  order?: SortOrder;
  filters?: Filters;
}

export interface ParamsWithoutPaginationInterface<
  Filters extends Record<string, JsonPrimitiveRecord> | JsonPrimitiveRecord
> {
  filters?: Filters;
}

export type SerializableQueryParams = ReadonlyDeep<
  QueryParamsInterface<JsonPrimitiveRecord>
>;

export type NonSerializableQueryParams = ReadonlyDeep<
  QueryParamsInterface<Record<string, JsonPrimitiveRecord>>
>;

export type ParamsWithoutPagination = ReadonlyDeep<
  ParamsWithoutPaginationInterface<JsonPrimitiveRecord>
>;

export const getHttpParams = <
  ExtraParams extends JsonPrimitiveRecord = Record<string, any>
>(
  params: SerializableQueryParams & ExtraParams
) => {
  const { page, pageSize, filters, order, sortBy, ...extraParams } = params;
  let httpParams = new HttpParams({
    fromObject: {
      page,
      pageSize,
    },
  });

  if (sortBy) {
    httpParams = httpParams
      .append('sortBy', sortBy)
      .append('order', order ?? SortOrder.Ascending);
  }

  if (filters) {
    httpParams = mapObjectParam(filters, httpParams);
  }

  if (extraParams) {
    httpParams = mapObjectParam(
      extraParams as any as JsonPrimitiveRecord,
      httpParams
    );
  }

  return httpParams;
};

export const getParamsWithoutPagination = <
  ExtraParams extends JsonPrimitiveRecord = Record<string, any>
>(
  params: ParamsWithoutPagination & ExtraParams
) => {
  const { filters, order, sortBy, ...extraParams } = params;
  let httpParams = new HttpParams({});

  if (filters) {
    httpParams = mapObjectParam(filters, httpParams);
  }

  if (extraParams) {
    httpParams = mapObjectParam(
      extraParams as any as JsonPrimitiveRecord,
      httpParams
    );
  }

  return httpParams;
};

const mapObjectParam = (
  param: ReadonlyDeep<JsonPrimitiveRecord>,
  httpParams: HttpParams
) =>
  Object.entries(param).reduce((result, [key, value]) => {
    if (value == null) {
      return result;
    }

    if (Array.isArray(value)) {
      return mapArrayParam(key, value as readonly JsonPrimitive[], result);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return result.append(key, value as any);
  }, httpParams);

const mapArrayParam = (
  key: string,
  arrayValue: readonly JsonPrimitive[],
  httpParams: HttpParams
) => {
  const arrayKey = `${key}[]`;

  return arrayValue.reduce((result, value) => {
    if (value == null) {
      return result;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return result.append(arrayKey, value as any);
  }, httpParams);
};
