import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  init as initSentry,
  routingInstrumentation as sentryRoutingInstrumentation,
} from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare const RELEASE: string | undefined;

const getRelease = () => {
  let release: string;

  try {
    release = RELEASE as string;
  } catch {
    release = 'dev';
  }

  return release;
};

if (environment.production) {
  enableProdMode();

  initSentry({
    dsn: environment.sentry.dsn,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new BrowserTracing({
        tracingOrigins: [
          'https://dev.consalio.com',
          'https://int-fev2r.consalio.com',
          'https://app.consalio.com',
        ],
        routingInstrumentation: sentryRoutingInstrumentation,
      }),
    ],
    release: getRelease(),
    environment: environment.production ? 'production' : 'development',
    attachStacktrace: !environment.production,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.production ? 0.2 : 1.0,
    initialScope: (scope) => scope.setTag('where', 'frontend'),
  });
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
