import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AriaLivePoliteness } from '@angular/cdk/a11y';
import { Platform } from '@angular/cdk/platform';
import {
  BasePortalOutlet,
  CdkPortalOutlet,
  ComponentPortal,
  DomPortal,
  TemplatePortal,
} from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  HostBinding,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Observable, Subject, take } from 'rxjs';

import { TOAST_CONFIG } from '../../injectables/toast-config';
import { ToastConfig } from '../../types/toast-config';
import { ToastContainer } from '../../types/toast-container';

declare let ngDevMode: any;

@Component({
  selector: 'consalio-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('popIn', [
      state(
        'void, hidden',
        style({
          transform: 'scale(0.3)',
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          transform: 'scale(1)',
          opacity: 1,
        })
      ),
      transition('* => visible', animate('150ms cubic-bezier(0, 0, 0.2, 1)')),
      transition(
        '* => void, * => hidden',
        animate(
          '75ms cubic-bezier(0.4, 0.0, 1, 1)',
          style({
            opacity: 0,
          })
        )
      ),
    ]),
  ],
  host: {
    class: 'block w-128 m-6 rounded-xl shadow bg-white overflow-hidden',
  },
})
export class ToastContainerComponent
  extends BasePortalOutlet
  implements ToastContainer, OnDestroy
{
  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet!: CdkPortalOutlet;

  @HostBinding('@popIn')
  animationState = 'void';

  ariaLivePoliteness: AriaLivePoliteness;

  readonly liveAnnounced$ = new Subject<void>();
  readonly exited$ = new Subject<void>();
  readonly entered$ = new Subject<void>();

  private readonly liveAnnounceDelay: number = 150;
  private liveAnnounceTimeoutId?: any;
  private isDestroyed = false;

  constructor(
    private ngZone: NgZone,
    private elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
    private platform: Platform,
    @Inject(TOAST_CONFIG) public toastConfig: ToastConfig
  ) {
    super();

    if (
      toastConfig.politeness === 'assertive' &&
      !toastConfig.announcementMessage
    ) {
      this.ariaLivePoliteness = 'assertive';
    } else if (toastConfig.politeness === 'off') {
      this.ariaLivePoliteness = 'off';
    } else {
      this.ariaLivePoliteness = 'polite';
    }
  }

  @HostListener('@popIn.done', ['$event'])
  onAnimationEnd(event: AnimationEvent) {
    const { fromState, toState } = event;

    if ((toState === 'void' && fromState !== 'void') || toState === 'hidden') {
      this.completeExit();
    }

    if (toState === 'visible') {
      const onEnter = this.entered$;

      this.ngZone.run(() => {
        onEnter.next();
        onEnter.complete();
      });
    }
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    this.assertNotAttached();

    return this.portalOutlet.attachComponentPortal(portal);
  }

  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    this.assertNotAttached();

    return this.portalOutlet.attachTemplatePortal(portal);
  }

  attachDomPortal = (portal: DomPortal) => {
    this.assertNotAttached();

    // tslint:disable-next-line: deprecation
    return this.portalOutlet.attachDomPortal(portal);
  };

  enter(): void {
    if (!this.isDestroyed) {
      this.animationState = 'visible';
      this.changeDetectorRef.detectChanges();
      this.screenReaderAnnounce();
    }
  }

  exit(): Observable<void> {
    this.animationState = 'hidden';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(this.liveAnnounceTimeoutId);

    return this.exited$;
  }

  ngOnDestroy() {
    this.isDestroyed = true;
    this.completeExit();
  }

  private completeExit() {
    this.ngZone.onMicrotaskEmpty.pipe(take(1)).subscribe(() => {
      this.exited$.next();
      this.exited$.complete();
    });
  }

  private assertNotAttached() {
    if (
      this.portalOutlet.hasAttached() &&
      (typeof ngDevMode === 'undefined' || ngDevMode)
    ) {
      throw Error(
        'Attempting to attach toast content after content is already attached'
      );
    }
  }

  private screenReaderAnnounce() {
    if (!this.liveAnnounceTimeoutId) {
      this.ngZone.runOutsideAngular(() => {
        this.liveAnnounceTimeoutId = setTimeout(() => {
          const inertElement =
            this.elementRef.nativeElement.querySelector('[aria-hidden]');
          const liveElement =
            this.elementRef.nativeElement.querySelector('[aria-live]');

          if (inertElement && liveElement) {
            let focusedElement: HTMLElement | null = null;
            if (
              this.platform.isBrowser &&
              document.activeElement instanceof HTMLElement &&
              inertElement.contains(document.activeElement)
            ) {
              focusedElement = document.activeElement;
            }

            inertElement.removeAttribute('aria-hidden');
            liveElement.appendChild(inertElement);
            focusedElement?.focus();

            this.liveAnnounced$.next();
            this.liveAnnounced$.complete();
          }
        }, this.liveAnnounceDelay);
      });
    }
  }
}
