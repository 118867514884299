import { FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import {
  BooleanInput,
  coerceBooleanProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import {
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  OriginConnectionPosition,
  Overlay,
  OverlayConnectionPosition,
  OverlayRef,
  ScrollStrategy,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import {
  normalizePassiveListenerOptions,
  Platform,
} from '@angular/cdk/platform';
import { ComponentPortal } from '@angular/cdk/portal';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject, take, takeUntil } from 'rxjs';

import { TooltipComponent } from './tooltip.component';

declare let ngDevMode: any;

/** Possible positions for a tooltip. */
export type TooltipPosition =
  | 'left'
  | 'right'
  | 'above'
  | 'below'
  | 'before'
  | 'after';

export type TooltipTouchGestures = 'auto' | 'on' | 'off';

export const SCROLL_THROTTLE_MS = 20;
const LONGPRESS_DELAY = 500;

export const TOOLTIP_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>(
  '[Tooltip] Scroll Strategy'
);

export const TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: TOOLTIP_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: (overlay: Overlay) => {
    return () =>
      overlay.scrollStrategies.reposition({
        scrollThrottle: SCROLL_THROTTLE_MS,
      });
  },
};

export interface TooltipDefaultOptions {
  showDelay: number;
  hideDelay: number;
  touchendHideDelay: number;
  touchGestures?: TooltipTouchGestures;
  position?: TooltipPosition;
}

export const TOOLTIP_DEFAULT_OPTIONS_FACTORY = (): TooltipDefaultOptions => {
  return {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 1500,
  };
};

export const TOOLTIP_DEFAULT_OPTIONS =
  new InjectionToken<TooltipDefaultOptions>('[Tooltip] Default Options', {
    providedIn: 'root',
    factory: TOOLTIP_DEFAULT_OPTIONS_FACTORY,
  });

const passiveListenerOptions = normalizePassiveListenerOptions({
  passive: true,
});

@Directive({
  selector: '[consalioTooltip]',
  exportAs: 'consalioTooltip',
})
export class TooltipDirective implements OnDestroy, AfterViewInit {
  static ngAcceptInputType_disabled: BooleanInput;
  static ngAcceptInputType_hideDelay: NumberInput;
  static ngAcceptInputType_showDelay: NumberInput;

  @Input() tooltipShowDelay: number = this.defaultOptions.showDelay;
  @Input() tooltipHideDelay: number = this.defaultOptions.hideDelay;

  /**
   * How touch gestures should be handled by the tooltip. On touch devices the tooltip directive
   * uses a long press gesture to show and hide, however it can conflict with the native browser
   * gestures. To work around the conflict, Angular Material disables native gestures on the
   * trigger, but that might not be desirable on particular elements (e.g. inputs and draggable
   * elements). The different values for this option configure the touch event handling as follows:
   * - `auto` - Enables touch gestures for all elements, but tries to avoid conflicts with native
   *   browser gestures on particular elements. In particular, it allows text selection on inputs
   *   and textareas, and preserves the native browser dragging on elements marked as `draggable`.
   * - `on` - Enables touch gestures for all elements and disables native
   *   browser gestures with no exceptions.
   * - `off` - Disables touch gestures. Note that this will prevent the tooltip from
   *   showing on touch devices.
   */
  @Input() tooltipTouchGestures: TooltipTouchGestures = 'auto';

  @Input('consalioTooltip')
  get message() {
    return this._message;
  }

  set message(value) {
    this._message = value;

    if (!this._message && this.isTooltipVisible()) {
      this.hide(0);
    } else {
      this.setupPointerEnterEventsIfNeeded();
      this.updateTooltipMessage();
    }
  }

  @Input()
  get tooltipPosition() {
    return this.position;
  }

  set tooltipPosition(value) {
    if (value !== this.position) {
      this.position = value;

      if (this.overlayRef) {
        this.updatePosition();
        this.tooltipInstance?.show(0);
        this.overlayRef.updatePosition();
      }
    }
  }

  @Input()
  get tooltipDisabled() {
    return this.isDisabled;
  }

  set tooltipDisabled(value) {
    this.isDisabled = coerceBooleanProperty(value);

    if (this.isDisabled) {
      this.hide(0);
    } else {
      this.setupPointerEnterEventsIfNeeded();
    }
  }

  private _message: string | TemplateRef<void> = '';
  private overlayRef?: OverlayRef;
  private tooltipInstance?: TooltipComponent;
  private portal?: ComponentPortal<TooltipComponent>;
  private position: TooltipPosition = 'below';
  private isDisabled = false;
  private isViewInitialized = false;
  private isPointerExitEventsInitialized = false;
  private readonly passiveListeners: (readonly [
    string,
    EventListenerOrEventListenerObject
  ])[] = [];
  private touchstartTimeoutId?: any;
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private scrollDispatcher: ScrollDispatcher,
    private viewContainerRef: ViewContainerRef,
    private ngZone: NgZone,
    private platform: Platform,
    private focusMonitor: FocusMonitor,
    @Inject(TOOLTIP_SCROLL_STRATEGY)
    private scrollStrategy: () => ScrollStrategy,
    @Inject(DOCUMENT) private document: Document,
    @Inject(TOOLTIP_DEFAULT_OPTIONS)
    private defaultOptions: TooltipDefaultOptions,
    @Optional() private directionality?: Directionality
  ) {
    if (this.defaultOptions.position) {
      this.tooltipPosition = this.defaultOptions.position;
    }

    if (this.defaultOptions.touchGestures) {
      this.tooltipTouchGestures = this.defaultOptions.touchGestures;
    }

    this.ngZone.runOutsideAngular(() => {
      this.elementRef.nativeElement.addEventListener(
        'keydown',
        this.handleKeydown
      );
    });
  }

  ngAfterViewInit() {
    this.isViewInitialized = true;
    this.setupPointerEnterEventsIfNeeded();

    this.focusMonitor
      .monitor(this.elementRef)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((origin) => {
        if (!origin) {
          this.ngZone.run(() => this.hide(0));
        } else if (origin === 'keyboard') {
          this.ngZone.run(() => this.show());
        }
      });
  }

  ngOnDestroy() {
    const nativeElement = this.elementRef.nativeElement;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(this.touchstartTimeoutId);

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.tooltipInstance = undefined;
    }

    nativeElement.removeEventListener('keydown', this.handleKeydown);
    this.passiveListeners.forEach(([event, listener]) => {
      nativeElement.removeEventListener(
        event,
        listener,
        passiveListenerOptions
      );
    });
    this.passiveListeners.length = 0;

    this.destroyed$.next();
    this.destroyed$.complete();

    this.focusMonitor.stopMonitoring(nativeElement);
  }

  show(delay: number = this.tooltipShowDelay) {
    if (
      this.tooltipDisabled ||
      !this.message ||
      (this.isTooltipVisible() &&
        !this.tooltipInstance?.showTimeoutId &&
        !this.tooltipInstance?.hideTimeoutId)
    ) {
      return;
    }

    const overlayRef = this.createOverlay();

    this.detach();
    this.portal =
      this.portal ||
      new ComponentPortal(TooltipComponent, this.viewContainerRef);
    this.tooltipInstance = overlayRef.attach(this.portal).instance;
    this.tooltipInstance
      .afterHidden()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.detach());
    this.updateTooltipMessage();
    this.tooltipInstance.show(delay);
  }

  hide(delay: number = this.tooltipHideDelay) {
    if (this.tooltipInstance) {
      this.tooltipInstance.hide(delay);
    }
  }

  toggle() {
    if (this.isTooltipVisible()) {
      this.hide();
    } else {
      this.show();
    }
  }

  isTooltipVisible() {
    return !!this.tooltipInstance && this.tooltipInstance.isVisible();
  }

  private handleKeydown = (event: KeyboardEvent) => {
    if (
      this.isTooltipVisible() &&
      event.keyCode === ESCAPE &&
      !hasModifierKey(event)
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.ngZone.run(() => this.hide(0));
    }
  };

  private createOverlay(): OverlayRef {
    if (this.overlayRef) {
      return this.overlayRef;
    }

    const scrollableAncestors =
      this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);

    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withTransformOriginOn('consalio-tooltip')
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withScrollableContainers(scrollableAncestors);

    strategy.positionChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((change) => {
        if (this.tooltipInstance) {
          if (
            change.scrollableViewProperties.isOverlayClipped &&
            this.tooltipInstance.isVisible()
          ) {
            this.ngZone.run(() => this.hide(0));
          }
        }
      });

    this.overlayRef = this.overlay.create({
      direction: this.directionality,
      positionStrategy: strategy,
      panelClass: 'pointer-events-none',
      scrollStrategy: this.scrollStrategy(),
    });

    this.updatePosition();

    this.overlayRef
      .detachments()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.detach());

    return this.overlayRef;
  }

  private detach() {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }

    this.tooltipInstance = undefined;
  }

  private updatePosition() {
    const position = this.overlayRef?.getConfig()
      .positionStrategy as FlexibleConnectedPositionStrategy;
    const origin = this.getOrigin();
    const overlay = this.getOverlayPosition();

    position.withPositions([
      { ...origin.main, ...overlay.main },
      { ...origin.fallback, ...overlay.fallback },
    ]);
  }

  private getOrigin(): {
    main: OriginConnectionPosition;
    fallback: OriginConnectionPosition;
  } {
    const isLtr = !this.directionality || this.directionality.value === 'ltr';
    const position = this.tooltipPosition;
    let originPosition!: OriginConnectionPosition;

    if (position === 'above' || position === 'below') {
      originPosition = {
        originX: 'center',
        originY: position === 'above' ? 'top' : 'bottom',
      };
    } else if (
      position === 'before' ||
      (position === 'left' && isLtr) ||
      (position === 'right' && !isLtr)
    ) {
      originPosition = { originX: 'start', originY: 'center' };
    } else if (
      position === 'after' ||
      (position === 'right' && isLtr) ||
      (position === 'left' && !isLtr)
    ) {
      originPosition = { originX: 'end', originY: 'center' };
    } else if (typeof ngDevMode === 'undefined' || ngDevMode) {
      throw new Error(`Tooltip position "${position}" is invalid.`);
    }

    const { x, y } = this.invertPosition(
      originPosition.originX,

      originPosition.originY
    );

    return {
      main: originPosition,
      fallback: { originX: x, originY: y },
    };
  }

  private getOverlayPosition(): {
    main: OverlayConnectionPosition;
    fallback: OverlayConnectionPosition;
  } {
    const isLtr = !this.directionality || this.directionality.value === 'ltr';
    const position = this.tooltipPosition;
    let overlayPosition!: OverlayConnectionPosition;

    if (position === 'above') {
      overlayPosition = { overlayX: 'center', overlayY: 'bottom' };
    } else if (position === 'below') {
      overlayPosition = { overlayX: 'center', overlayY: 'top' };
    } else if (
      position === 'before' ||
      (position === 'left' && isLtr) ||
      (position === 'right' && !isLtr)
    ) {
      overlayPosition = { overlayX: 'end', overlayY: 'center' };
    } else if (
      position === 'after' ||
      (position === 'right' && isLtr) ||
      (position === 'left' && !isLtr)
    ) {
      overlayPosition = { overlayX: 'start', overlayY: 'center' };
    } else if (typeof ngDevMode === 'undefined' || ngDevMode) {
      throw new Error(`Tooltip position "${position}" is invalid.`);
    }

    const { x, y } = this.invertPosition(
      overlayPosition.overlayX,
      overlayPosition.overlayY
    );

    return {
      main: overlayPosition,
      fallback: { overlayX: x, overlayY: y },
    };
  }

  private updateTooltipMessage() {
    if (this.tooltipInstance) {
      this.tooltipInstance.message = this.message;
      this.tooltipInstance.changeDetectorRef.markForCheck();

      this.ngZone.onMicrotaskEmpty
        .pipe(take(1), takeUntil(this.destroyed$))
        .subscribe(() => {
          if (this.tooltipInstance) {
            this.overlayRef?.updatePosition();
          }
        });
    }
  }

  private invertPosition(x: HorizontalConnectionPos, y: VerticalConnectionPos) {
    if (this.tooltipPosition === 'above' || this.tooltipPosition === 'below') {
      if (y === 'top') {
        y = 'bottom';
      } else if (y === 'bottom') {
        y = 'top';
      }
    } else {
      if (x === 'end') {
        x = 'start';
      } else if (x === 'start') {
        x = 'end';
      }
    }

    return { x, y };
  }

  private setupPointerEnterEventsIfNeeded() {
    if (
      this.isDisabled ||
      !this.message ||
      !this.isViewInitialized ||
      this.passiveListeners.length
    ) {
      return;
    }

    // The mouse events shouldn't be bound on mobile devices, because they can prevent the
    // first tap from firing its click event or can cause the tooltip to open for clicks.
    if (this.platformSupportsMouseEvents()) {
      this.passiveListeners.push([
        'mouseenter',
        () => {
          this.setupPointerExitEventsIfNeeded();
          this.show();
        },
      ]);
    } else if (this.tooltipTouchGestures !== 'off') {
      this.disableNativeGesturesIfNecessary();

      this.passiveListeners.push([
        'touchstart',
        () => {
          // Note that it's important that we don't `preventDefault` here,
          // because it can prevent click events from firing on the element.
          this.setupPointerExitEventsIfNeeded();
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          clearTimeout(this.touchstartTimeoutId);
          this.touchstartTimeoutId = setTimeout(
            () => this.show(),
            LONGPRESS_DELAY
          );
        },
      ]);
    }

    this.addListeners(this.passiveListeners);
  }

  private setupPointerExitEventsIfNeeded() {
    if (this.isPointerExitEventsInitialized) {
      return;
    }
    this.isPointerExitEventsInitialized = true;

    const exitListeners: (readonly [
      string,
      EventListenerOrEventListenerObject
    ])[] = [];
    if (this.platformSupportsMouseEvents()) {
      exitListeners.push(
        ['mouseleave', () => this.hide()],
        ['wheel', (event) => this.wheelListener(event as WheelEvent)]
      );
    } else if (this.tooltipTouchGestures !== 'off') {
      this.disableNativeGesturesIfNecessary();
      const touchendListener = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        clearTimeout(this.touchstartTimeoutId);
        this.hide(this.defaultOptions.touchendHideDelay);
      };

      exitListeners.push(
        ['touchend', touchendListener],
        ['touchcancel', touchendListener]
      );
    }

    this.addListeners(exitListeners);
    this.passiveListeners.push(...exitListeners);
  }

  private addListeners(
    listeners: ReadonlyArray<
      readonly [string, EventListenerOrEventListenerObject]
    >
  ) {
    listeners.forEach(([event, listener]) => {
      this.elementRef.nativeElement.addEventListener(
        event,
        listener,
        passiveListenerOptions
      );
    });
  }

  private platformSupportsMouseEvents() {
    return !this.platform.IOS && !this.platform.ANDROID;
  }

  private wheelListener(event: WheelEvent) {
    if (this.isTooltipVisible()) {
      const doc = this.document;
      const elementUnderPointer = doc.elementFromPoint(
        event.clientX,
        event.clientY
      );
      const element = this.elementRef.nativeElement;

      // On non-touch devices we depend on the `mouseleave` event to close the tooltip, but it
      // won't fire if the user scrolls away using the wheel without moving their cursor. We
      // work around it by finding the element under the user's cursor and closing the tooltip
      // if it's not the trigger.
      if (
        elementUnderPointer !== element &&
        !element.contains(elementUnderPointer)
      ) {
        this.hide();
      }
    }
  }

  /** Disables the native browser gestures, based on how the tooltip has been configured. */
  private disableNativeGesturesIfNecessary() {
    const gestures = this.tooltipTouchGestures;

    if (gestures !== 'off') {
      const element = this.elementRef.nativeElement;
      const style = element.style;

      // If gestures are set to `auto`, we don't disable text selection on inputs and
      // textareas, because it prevents the user from typing into them on iOS Safari.
      if (
        gestures === 'on' ||
        (element.nodeName !== 'INPUT' && element.nodeName !== 'TEXTAREA')
      ) {
        style.userSelect =
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (style as any).msUserSelect =
          style.webkitUserSelect =
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (style as any).MozUserSelect =
            'none';
      }

      // If we have `auto` gestures and the element uses native HTML dragging,
      // we don't set `-webkit-user-drag` because it prevents the native behavior.
      if (gestures === 'on' || !element.draggable) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (style as any).webkitUserDrag = 'none';
      }

      style.touchAction = 'none';
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (style as any).webkitTapHighlightColor = 'transparent';
    }
  }
}
