import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatomoModule } from '@consalio/shared/matomo';

import { TabBodyComponent } from './components/tab-body/tab-body.component';
import { TabComponent } from './components/tab/tab.component';
import { TabsGroupComponent } from './components/tabs-group/tabs-group.component';
import { TabBodyPortalDirective } from './directives/tab-body-portal.directive';
import { TabContentDirective } from './directives/tab-content.directive';
import { TabLabelDirective } from './directives/tab-label.directive';

@NgModule({
  imports: [CommonModule, PortalModule, MatomoModule],
  declarations: [
    TabsGroupComponent,
    TabBodyComponent,
    TabComponent,
    TabContentDirective,
    TabLabelDirective,
    TabBodyPortalDirective,
  ],
  exports: [
    TabsGroupComponent,
    TabComponent,
    TabContentDirective,
    TabLabelDirective,
  ],
})
export class TabsModule {}
