import { createReducer, on } from '@ngrx/store';

import { sessionResumed } from '../actions/auth-domain.actions';
import { userLoggedIn } from '../actions/login.actions';

export interface AuthState {
  token?: string;
}

const initialState: AuthState = {
  token: undefined,
};

export const reducer = createReducer(
  initialState,
  on(
    userLoggedIn,
    sessionResumed,
    (state, action): AuthState => ({
      ...state,
      token: action.token,
    })
  )
);

export const featureKey = 'auth';
