import { InjectionToken } from '@angular/core';

import { ToastConfig } from '../types/toast-config';

export const TOASTS_DEFAULT_CONFIG = new InjectionToken<ToastConfig>(
  '[Toasts] Toasts Default Config',
  {
    providedIn: 'root',
    factory: (): ToastConfig => ({
      direction: 'ltr',
      duration: 5000,
      horizontalPosition: 'center',
      politeness: 'assertive',
      verticalPosition: 'bottom',
    }),
  }
);
