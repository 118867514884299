import { Directive, HostListener, Input, NgZone } from '@angular/core';
import { MatomoTracker } from '@ngx-matomo/tracker';

import { MatomoCategoryDirective } from './matomo-category.directive';

@Directive({
  selector: '[consalioMatomoClickAction]',
})
export class MatomoClickDirective {
  @Input('consalioMatomoClickAction') action!: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('consalioMatomoClickName') name?: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('consalioMatomoClickValue') value?: number;

  constructor(
    private matomoCategoryDirective: MatomoCategoryDirective,
    private ngZone: NgZone,
    private matomoTracker: MatomoTracker
  ) {}

  @HostListener('click')
  onClick(): void {
    this.ngZone.runOutsideAngular(() => {
      this.matomoTracker.trackEvent(
        this.matomoCategoryDirective.category,
        this.action,
        this.name,
        this.value
      );
    });
  }
}
