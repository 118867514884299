export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface Sorting<SortBy extends string = string> {
  sortBy: SortBy;
  order: SortOrder;
}

export const mapSorting = (
  sorting: Partial<Sorting>,
  mappings: Partial<Record<string, string>>
) => {
  if (!sorting.sortBy) {
    return undefined;
  }

  const sortBy = mappings[sorting.sortBy];

  if (!sortBy) {
    return undefined;
  }

  return { sortBy, order: sorting.order || SortOrder.Ascending };
};
