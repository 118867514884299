import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ToastsModule } from '@consalio/shared/toasts';

import { AuthEffects } from './effects/auth.effects';
import * as authReducer from './reducers/auth.reducer';
import { AuthInterceptor } from './services/auth-interceptor';

export const storeModule = StoreModule.forFeature(
  authReducer.featureKey,
  authReducer.reducer
);
export const effectsModule = EffectsModule.forFeature([AuthEffects]);

@NgModule({
  imports: [storeModule, effectsModule, ToastsModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class AuthDomainModule {}
