import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  filter,
  map,
  of,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs';

import { LocalStorageService } from '@consalio/shared/local-storage';
import { routerSelectors } from '@consalio/shared/router-store';

import * as authDomainActions from '../actions/auth-domain.actions';
import * as layoutActions from '../actions/layout.actions';
import * as loginActions from '../actions/login.actions';
import { AuthApiService } from '../services/auth-api.service';

@Injectable()
export class AuthEffects {
  resumeSession$ = createEffect(() => {
    return of(this.localStorageService.getItem('token')).pipe(
      filter((token): token is string => !!token),
      map((token) => authDomainActions.sessionResumed({ token }))
    );
  });

  userLoggedIn$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(loginActions.userLoggedIn),
        filter(({ rememberMe }) => rememberMe),
        tap(({ token }) => this.localStorageService.setItem('token', token))
      );
    },
    { dispatch: false }
  );

  logout$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(layoutActions.logout),
        switchMap(() =>
          this.authApiService.logout().pipe(
            catchError(() => of(true)),
            tap(() => {
              this.localStorageService.removeItem('token');

              this.router.navigate(['/', 'auth', 'login'], {
                queryParams: {
                  returnUrl: this.router.url.replace(/^\//, ''),
                },
              });
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  tokenExpired$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(authDomainActions.tokenExpired),
        throttleTime(1000),
        concatLatestFrom(() => this.store.select(routerSelectors.selectUrl)),
        filter(([, url]) => !(url && url.startsWith('/auth'))),
        tap(() => {
          this.router.navigate(['/', 'auth', 'login'], {
            queryParams: {
              returnUrl: this.router.url.replace(/^\//, ''),
            },
          });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions: Actions,
    private localStorageService: LocalStorageService,
    private authApiService: AuthApiService,
    private router: Router,
    private store: Store
  ) {}
}
