import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, switchMap } from 'rxjs';

import { authDomainActions, loginActions } from '@consalio/auth/api';
import { ToastsService } from '@consalio/shared/toasts';

import { accountLoaded } from '../actions/account-domain.actions';
import { AccountApiService } from '../services/account-api.service';

@Injectable()
export class AccountEffects {
  loadAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginActions.userLoggedIn, authDomainActions.sessionResumed),
      switchMap(() =>
        this.accountApiService.getAccount().pipe(
          map((account) => accountLoaded({ account })),
          catchError((_error: Error) => {
            if (_error instanceof HttpErrorResponse) {
              const error = _error;
              if (
                error.error &&
                typeof error.error === 'string' &&
                error.error.length > 0
              ) {
                this.toastsService.open(error.error.toString(), 'error');
                return EMPTY;
              }
            }
            this.toastsService.open(
              'You are not authorized to access consalio.',
              'error'
            );
            return EMPTY;
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private accountApiService: AccountApiService,
    private toastsService: ToastsService
  ) {}
}
