import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AccountEffects } from './effects/account.effects';
import { featureKey, reducer } from './reducers/account.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureKey, reducer),
    EffectsModule.forFeature([AccountEffects]),
  ],
})
export class AccountDomainModule {}
