<ng-container *ngIf="message">
  <ng-container *ngIf="isStringMessage; else templateRefMessageTemplate">
    {{ message }}
  </ng-container>
</ng-container>

<ng-template #templateRefMessageTemplate>
  <!-- eslint-disable-next-line @angular-eslint/template/no-any -->
  <ng-container *ngTemplateOutlet="$any(message)"></ng-container>
</ng-template>
