import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes,
  AnimationEvent,
} from '@angular/animations';
import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding,
  HostListener,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { Subject } from 'rxjs';

export type TooltipVisibility = 'initial' | 'visible' | 'hidden';

@Component({
  selector: 'consalio-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tooltipState', [
      state(
        'initial, void, hidden',
        style({ opacity: 0, transform: 'scale(0)' })
      ),
      state('visible', style({ transform: 'scale(1)' })),
      transition(
        '* => visible',
        animate(
          '200ms cubic-bezier(0, 0, 0.2, 1)',
          keyframes([
            style({ opacity: 0, transform: 'scale(0)', offset: 0 }),
            style({ opacity: 0.5, transform: 'scale(0.99)', offset: 0.5 }),
            style({ opacity: 1, transform: 'scale(1)', offset: 1 }),
          ])
        )
      ),
      transition(
        '* => hidden',
        animate('100ms cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 0 }))
      ),
    ]),
  ],
  host: {
    class: 'block py-2 px-4 shadow rounded bg-gray text-white text-xs',
  },
})
export class TooltipComponent implements OnDestroy {
  @HostBinding('@tooltipState')
  tooltipState: TooltipVisibility = 'initial';

  message!: string | TemplateRef<void>;
  showTimeoutId?: any;
  hideTimeoutId?: any;

  get isStringMessage() {
    return typeof this.message === 'string';
  }

  private closeOnInteraction = false;
  private readonly onHide$ = new Subject<void>();

  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  @HostListener('@tooltipState.start')
  onAnimationStart() {
    this.closeOnInteraction = false;
  }

  @HostListener('@tooltipState.done', ['$event'])
  onAnimationDone(event: AnimationEvent): void {
    const toState = event.toState as TooltipVisibility;

    if (toState === 'hidden' && !this.isVisible()) {
      this.onHide$.next();
    }

    if (toState === 'visible' || toState === 'hidden') {
      this.closeOnInteraction = true;
    }
  }

  show(delay: number) {
    if (this.hideTimeoutId) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = undefined;
    }

    this.closeOnInteraction = true;
    this.showTimeoutId = setTimeout(() => {
      this.tooltipState = 'visible';
      this.showTimeoutId = undefined;

      this.changeDetectorRef.markForCheck();
    }, delay);
  }

  hide(delay: number) {
    if (this.showTimeoutId) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      clearTimeout(this.showTimeoutId);
      this.showTimeoutId = undefined;
    }

    this.hideTimeoutId = setTimeout(() => {
      this.tooltipState = 'hidden';
      this.hideTimeoutId = undefined;

      this.changeDetectorRef.markForCheck();
    }, delay);
  }

  afterHidden() {
    return this.onHide$.asObservable();
  }

  isVisible() {
    return this.tooltipState === 'visible';
  }

  ngOnDestroy() {
    this.onHide$.complete();
  }

  handleBodyInteraction() {
    if (this.closeOnInteraction) {
      this.hide(0);
    }
  }
}
