import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatomoModule } from '@consalio/shared/matomo';

import { SimpleToastComponent } from './components/simple-toast/simple-toast.component';
import { ToastContainerComponent } from './components/toast-container/toast-container.component';

@NgModule({
  imports: [CommonModule, PortalModule, OverlayModule, MatomoModule],
  declarations: [SimpleToastComponent, ToastContainerComponent],
})
export class ToastsModule {}
