import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipComponent } from './tooltip.component';
import {
  TooltipDirective,
  TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER,
} from './tooltip.directive';

@NgModule({
  imports: [CommonModule, A11yModule, OverlayModule],
  declarations: [TooltipComponent, TooltipDirective],
  exports: [TooltipDirective],
  providers: [TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER],
})
export class TooltipModule {}
