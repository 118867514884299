import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  ItemWithSlug,
  SerializableQueryParams,
  getHttpParams,
} from '@consalio/shared/types';

import { AccountDto } from '../dto/account.dto';

@Injectable({ providedIn: 'root' })
export class AccountApiService {
  constructor(private httpClient: HttpClient) {}

  getAccount() {
    return this.httpClient.get<AccountDto>(`/api/me`);
  }

  updateMappingLearning() {
    return this.httpClient.get(`api/account/learning`);
  }

  getAccounts(params: SerializableQueryParams) {
    return this.httpClient.get<ItemWithSlug[]>(`api/accounts`, {
      params: getHttpParams(params),
    });
  }
}
