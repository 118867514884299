import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';

import { ToastContainer } from '../types/toast-container';

export interface ToastDismiss {
  dismissedByAction: boolean;
}

const MAX_TIMEOUT = Math.pow(2, 31) - 1;

export class ToastRef<T> {
  instance!: T;

  private readonly dismissed$ = new Subject<ToastDismiss>();
  private readonly opened$ = new Subject<void>();
  private readonly actionClicked$ = new Subject<void>();
  private durationTimeoutId?: any;
  private dismissedByAction = false;

  constructor(
    readonly toastContainer: ToastContainer,
    private overlayRef: OverlayRef
  ) {
    this.actionClicked$.subscribe(() => this.dismiss());
    this.toastContainer.exited$.subscribe(() => this.finishDismissal());
  }

  dismiss(): void {
    if (!this.dismissed$.closed) {
      this.toastContainer.exit();
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(this.durationTimeoutId);
  }

  dismissWithAction(): void {
    if (!this.actionClicked$.closed) {
      this.dismissedByAction = true;
      this.actionClicked$.next();
      this.actionClicked$.complete();
    }
  }

  dismissAfter(duration: number): void {
    this.durationTimeoutId = setTimeout(
      () => this.dismiss(),
      Math.min(duration, MAX_TIMEOUT)
    );
  }

  open(): void {
    if (!this.opened$.closed) {
      this.opened$.next();
      this.opened$.complete();
    }
  }

  afterDismissed(): Observable<ToastDismiss> {
    return this.dismissed$;
  }

  afterOpened(): Observable<void> {
    return this.toastContainer.entered$;
  }

  actionClicked(): Observable<void> {
    return this.actionClicked$.asObservable();
  }

  private finishDismissal(): void {
    this.overlayRef.dispose();

    if (!this.actionClicked$.closed) {
      this.actionClicked$.complete();
    }

    this.dismissed$.next({ dismissedByAction: this.dismissedByAction });
    this.dismissed$.complete();
    this.dismissedByAction = false;
  }
}
