import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
} from '@angular/core';

import { TOAST_DATA } from '../../injectables/toast-data';
import { ToastRef } from '../../injectables/toast-ref';

export interface SimpleToastData {
  message: string;
  action: string;
  context: string;
  kind: 'success' | 'error' | 'info' | 'warning';
}

export interface SimpleToast {
  data: SimpleToastData;
  toastRef: ToastRef<SimpleToast>;
  onAction: () => void;
  hasAction: boolean;
}

@Component({
  selector: 'consalio-simple-toast',
  templateUrl: './simple-toast.component.html',
  styleUrls: ['./simple-toast.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex px-6 py-2 items-center',
  },
})
export class SimpleToastComponent implements SimpleToast {
  @HostBinding('class.bg-error')
  @HostBinding('class.text-white')
  get isError() {
    return this.data.kind === 'error';
  }

  @HostBinding('class.bg-success')
  @HostBinding('class.text-white')
  get isSuccess() {
    return this.data.kind === 'success';
  }

  @HostBinding('class.bg-info')
  @HostBinding('class.text-white')
  get isInfo() {
    return this.data.kind === 'info';
  }

  @HostBinding('class.bg-warning')
  get isWarning() {
    return this.data.kind === 'warning';
  }

  get hasAction() {
    return !!this.data.action;
  }

  constructor(
    readonly toastRef: ToastRef<SimpleToast>,
    @Inject(TOAST_DATA) readonly data: SimpleToastData
  ) {}

  onAction() {
    this.toastRef.dismissWithAction();
  }
}
