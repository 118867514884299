import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatomoCategoryDirective } from './directives/matomo-category.directive';
import { MatomoClickDirective } from './directives/matomo-click.directive';
import { MatomoEventDirective } from './directives/matomo-event.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    MatomoCategoryDirective,
    MatomoClickDirective,
    MatomoEventDirective,
  ],
  exports: [
    MatomoCategoryDirective,
    MatomoClickDirective,
    MatomoEventDirective,
  ],
})
export class MatomoModule {}
