import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import {
  ComponentPortal,
  ComponentType,
  TemplatePortal,
} from '@angular/cdk/portal';
import {
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import {
  SimpleToast,
  SimpleToastComponent,
} from '../components/simple-toast/simple-toast.component';
import { ToastContainerComponent } from '../components/toast-container/toast-container.component';
import { TOAST_CONFIG } from '../injectables/toast-config';
import { TOAST_DATA } from '../injectables/toast-data';
import { ToastRef } from '../injectables/toast-ref';
import { TOASTS_DEFAULT_CONFIG } from '../injectables/toasts-default-config';
import { ToastConfig } from '../types/toast-config';

@Injectable({ providedIn: 'root' })
export class ToastsService {
  private openedSnackBarRef?: ToastRef<any>;

  constructor(
    private overlay: Overlay,
    private liveAnnouncer: LiveAnnouncer,
    private injector: Injector,
    @Inject(TOASTS_DEFAULT_CONFIG) private toastsDefaultConfig: ToastConfig
  ) {}

  openFromComponent<T>(
    component: ComponentType<T>,
    config?: ToastConfig
  ): ToastRef<T> {
    return this.attach(component, config);
  }

  openFromTemplate<T>(
    template: TemplateRef<T>,
    config?: ToastConfig
  ): ToastRef<EmbeddedViewRef<T>> {
    return this.attach<T>(template, config);
  }

  open(
    message: string,
    kind: 'success' | 'error' | 'info' | 'warning',
    action: string = 'Dismiss',
    config?: ToastConfig
  ): ToastRef<SimpleToast> {
    const normalizedConfig = { ...this.toastsDefaultConfig, ...config };

    normalizedConfig.data = { message, action, kind };

    if (normalizedConfig.announcementMessage === message) {
      normalizedConfig.announcementMessage = undefined;
    }

    return this.openFromComponent(SimpleToastComponent, normalizedConfig);
  }

  dismiss(): void {
    if (this.openedSnackBarRef) {
      this.openedSnackBarRef.dismiss();
    }
  }

  private attachToastContainer(
    overlayRef: OverlayRef,
    config: ToastConfig
  ): ToastContainerComponent {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;
    const injector = Injector.create({
      parent: userInjector || this.injector,
      providers: [{ provide: TOAST_CONFIG, useValue: config }],
    });
    const containerPortal = new ComponentPortal(
      ToastContainerComponent,
      config.viewContainerRef,
      injector
    );
    const containerRef: ComponentRef<ToastContainerComponent> =
      overlayRef.attach(containerPortal);

    containerRef.instance.toastConfig = config;

    return containerRef.instance;
  }

  private attach<T>(
    content: ComponentType<T>,
    userConfig?: ToastConfig
  ): ToastRef<T>;
  private attach<T>(
    content: TemplateRef<T>,
    userConfig?: ToastConfig<T>
  ): ToastRef<EmbeddedViewRef<T>>;
  private attach<T>(
    content: ComponentType<T> | TemplateRef<T>,
    userConfig?: ToastConfig
  ): ToastRef<T | EmbeddedViewRef<T>> {
    const config = {
      ...this.toastsDefaultConfig,
      ...userConfig,
    } as ToastConfig<T>;
    const overlayRef = this.createOverlay(config);
    const container = this.attachToastContainer(overlayRef, config);
    const snackBarRef = new ToastRef<T | EmbeddedViewRef<T>>(
      container,
      overlayRef
    );

    if (content instanceof TemplateRef) {
      const portal = new TemplatePortal(
        content,
        null as any as ViewContainerRef,
        {
          $implicit: config.data,
          snackBarRef,
        } as any
      );

      snackBarRef.instance = container.attachTemplatePortal(portal);
    } else {
      const injector = this.createInjector(config, snackBarRef);
      const portal = new ComponentPortal(content, undefined, injector);
      const contentRef = container.attachComponentPortal<T>(portal);

      snackBarRef.instance = contentRef.instance;
    }

    if (config.announcementMessage) {
      container.liveAnnounced$.subscribe(() => {
        this.liveAnnouncer.announce(
          config.announcementMessage as string,
          config.politeness
        );
      });
    }

    this._animateSnackBar(snackBarRef, config);
    this.openedSnackBarRef = snackBarRef;

    return snackBarRef;
  }

  private _animateSnackBar(snackBarRef: ToastRef<any>, config: ToastConfig) {
    snackBarRef.afterDismissed().subscribe(() => {
      if (this.openedSnackBarRef === snackBarRef) {
        this.openedSnackBarRef = undefined;
      }

      if (config.announcementMessage) {
        this.liveAnnouncer.clear();
      }
    });

    if (this.openedSnackBarRef) {
      this.openedSnackBarRef.afterDismissed().subscribe(() => {
        snackBarRef.toastContainer.enter();
      });
      this.openedSnackBarRef.dismiss();
    } else {
      snackBarRef.toastContainer.enter();
    }

    if (config.duration && config.duration > 0) {
      snackBarRef
        .afterOpened()
        .subscribe(() => snackBarRef.dismissAfter(config.duration as number));
    }
  }

  private createOverlay(config: ToastConfig): OverlayRef {
    const overlayConfig = new OverlayConfig();
    overlayConfig.direction = config.direction;

    const positionStrategy = this.overlay.position().global();
    const isRtl = config.direction === 'rtl';
    const isLeft =
      config.horizontalPosition === 'left' ||
      (config.horizontalPosition === 'start' && !isRtl) ||
      (config.horizontalPosition === 'end' && isRtl);
    const isRight = !isLeft && config.horizontalPosition !== 'center';

    if (isLeft) {
      positionStrategy.left('0');
    } else if (isRight) {
      positionStrategy.right('0');
    } else {
      positionStrategy.centerHorizontally();
    }

    if (config.verticalPosition === 'top') {
      positionStrategy.top('0');
    } else {
      positionStrategy.bottom('0');
    }

    overlayConfig.positionStrategy = positionStrategy;

    return this.overlay.create(overlayConfig);
  }

  private createInjector<T>(
    config: ToastConfig<T>,
    snackBarRef: ToastRef<T>
  ): Injector {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;

    return Injector.create({
      parent: userInjector || this.injector,
      providers: [
        { provide: ToastRef, useValue: snackBarRef },
        { provide: TOAST_DATA, useValue: config.data },
      ],
    });
  }
}
