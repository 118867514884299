import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { loginActions } from '@consalio/auth/domain';

@Injectable()
export class AppEffects {
  userLoggedIn$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginActions.userLoggedIn),
        tap(() => {
          //if router link has returnUrl, navigate to that
          if (this.router.url.includes('returnUrl')) {
            const myQueryParam = this.router.parseUrl(this.router.url)
              .queryParams['returnUrl'] as string;
            this.router.navigateByUrl(myQueryParam);
          } else {
            //else navigate to home
            this.router.navigate(['/']);
          }
        })
      );
    },
    { dispatch: false }
  );

  constructor(private actions$: Actions, private router: Router) {}
}
