import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CreateAccountDto } from '../dto/create-account.dto';
import { LoginDto } from '../dto/login.dto';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  constructor(private httpClient: HttpClient) {}

  login(loginDto: LoginDto): Observable<{ token: string }> {
    return this.httpClient.post<{ token: string }>('/api/login', loginDto);
  }

  logout() {
    return this.httpClient.get<void>(`/api/logout`);
  }

  createAccount(createAccountDto: CreateAccountDto) {
    return this.httpClient.post<void>('/api/register', createAccountDto);
  }

  activateAccount(token: string) {
    return this.httpClient.post<void>('/api/activateAccount', token);
  }

  forgotPassword(username: string) {
    return this.httpClient.post<void>('/api/forgotPassword', { username });
  }

  resetPassword(params: { token: string; password: string }) {
    return this.httpClient.post<void>('/api/resetPassword', params);
  }

  getInvitationDetails(invitation: string) {
    return this.httpClient.get<{
      email: string;
      company: string;
      firstName?: string;
      lastName?: string;
    }>(`/api/invitation/${invitation}`);
  }
}
