import { createReducer, on } from '@ngrx/store';

import { authDomainActions, layoutActions } from '@consalio/auth/api';

import { accountLoaded } from '../actions/account-domain.actions';
import { AccountDto } from '../dto/account.dto';

export interface AccountState {
  account?: AccountDto;
}

export const reducer = createReducer<AccountState>(
  { account: undefined },
  on(accountLoaded, (_, action): AccountState => ({ account: action.account })),
  on(
    layoutActions.logout,
    authDomainActions.tokenExpired,
    (): AccountState => ({
      account: undefined,
    })
  )
);

export const featureKey = 'account';
