import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  catchError,
  EMPTY,
  mergeMap,
  Observable,
  Subject,
  take,
  throttleTime,
  throwError,
} from 'rxjs';

import { ToastsService } from '@consalio/shared/toasts';

import { tokenExpired } from '../actions/auth-domain.actions';
import { selectToken } from '../selectors/auth.selectors';

const ignoredUrls = [
  '/api/login',
  '/api/register',
  '/api/activateAccount',
  '/api/forgotPassword',
  '/api/resetPassword',
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private unauthorized$ = new Subject<void>();

  constructor(private store: Store, private toastsService: ToastsService) {
    this.unauthorized$.pipe(throttleTime(1000)).subscribe(() => {
      this.store.dispatch(tokenExpired());
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (ignoredUrls.includes(req.url)) {
      return next.handle(req);
    }

    return this.store.select(selectToken).pipe(
      take(1),
      mergeMap((token) => {
        const request$ = token
          ? next.handle(
              req.clone({
                headers: req.headers.append('Authorization', `Bearer ${token}`),
              })
            )
          : next.handle(req);

        return request$.pipe(
          catchError((response: HttpResponse<unknown>) => {
            if (req.url.includes('/assets/')) {
              return throwError(() => response);
            }
            if (response.status === 401 && req.url === '/api/me') {
              if (this.isTokenExpired(token)) {
                this.toastsService.open(
                  'Your login has expired. Please login again.',
                  'warning'
                );
              } else {
                this.toastsService.open(
                  'You are not authorized to access consalio',
                  'error'
                );
              }

              this.unauthorized$.next();

              return EMPTY;
            } else if (response.status === 403 && req.url === '/api/me') {
              console.log(response.body);
              if (response.body && response.body instanceof String) {
                this.toastsService.open(response.body.toString(), 'error');
              } else {
                this.toastsService.open(
                  'User needs to be approved by an Account Administrator',
                  'error'
                );
              }
              this.unauthorized$.next();
            } else if (response.status === 401 && req.url !== '/api/login') {
              this.unauthorized$.next();

              return EMPTY;
            } else if (response.status === 403 && req.url === '/api/login') {
              console.log(response.body);
              if (response.body && response.body instanceof String) {
                this.toastsService.open(response.body.toString(), 'error');
              } else {
                this.toastsService.open(
                  'User needs to be approved by an Account Administrator',
                  'error'
                );
              }
              this.unauthorized$.next();

              return EMPTY;
            } else if (response.status === 504) {
              this.toastsService.open(
                'consalio is unavailable at the moment. Please try again later.',
                'error'
              );
              this.unauthorized$.next();

              return EMPTY;
            }

            return throwError(() => response);
          })
        );
      })
    );
  }

  private isTokenExpired(token?: string): boolean {
    if (!token) {
      return false;
    }
    const decodedToken = jwtDecode<JwtPayload>(token);
    if (decodedToken.exp === undefined) {
      return true;
    }
    const date = new Date(0);
    date.setUTCSeconds(decodedToken.exp);
    return !(date.valueOf() > new Date().valueOf());
  }
}
