{{ data.message }}
<button
  type="button"
  class="btn btn-primary ml-auto"
  consalioMatomoCategory="Toast message"
  [consalioMatomoClickAction]="data.action"
  [consalioMatomoClickName]="data.context"
  (click)="onAction()"
>
  {{ data.action }}
</button>
