import { createFeatureSelector, createSelector } from '@ngrx/store';
// import jwtDecode from 'jwt-decode';

import * as authReducer from '../reducers/auth.reducer';

// interface DecodedToken {
//   iat: number;
//   sub: string;
//   id: string;
//   role: string[];
// }

const selectState = createFeatureSelector<authReducer.AuthState>(
  authReducer.featureKey
);
export const selectToken = createSelector(selectState, (state) => state?.token);
export const selectIsUserLoggedIn = createSelector(
  selectToken,
  (token) => !!token
);
// const selectDecodedToken = createSelector(selectToken, (token) => {
//   if (!token) {
//     return undefined;
//   }

//   try {
//     return jwtDecode<DecodedToken>(token);
//   } catch {
//     return undefined;
//   }
// });
// export const selectUserId = createSelector(
//   selectDecodedToken,
//   (token) => token?.id
// );
