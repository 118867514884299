import {
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatomoTracker } from '@ngx-matomo/tracker';

import { MatomoCategoryDirective } from './matomo-category.directive';

@Directive({
  selector: '[consalioMatomoEvent]',
})
export class MatomoEventDirective implements OnInit {
  @Input('consalioMatomoEvent') event!: keyof GlobalEventHandlersEventMap;
  @Input('consalioMatomoEventAction') action!: string;
  @Input('consalioMatomoEventName') name?: string;
  @Input('consalioMatomoEventValue') value?: number;

  constructor(
    private matomoCategoryDirective: MatomoCategoryDirective,
    private ngZone: NgZone,
    private matomoTracker: MatomoTracker,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.renderer.listen(this.elementRef.nativeElement, this.event, () =>
        this.matomoTracker.trackEvent(
          this.matomoCategoryDirective.category,
          this.action,
          this.name,
          this.value
        )
      );
    });
  }
}
