import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const localStorageServiceFactory = (platformId: any): Storage => {
  if (isPlatformBrowser(platformId as string)) {
    return localStorage;
  }

  return {
    clear: () => {
      return;
    },
    getItem: (_key: string) => {
      return null;
    },
    key: (_index: number) => {
      return null;
    },
    length: 0,
    removeItem: (_key: string) => {
      return null;
    },
    setItem: (_key: string, _value: string) => {
      return;
    },
  };
};

@Injectable({
  providedIn: 'root',
  useFactory: localStorageServiceFactory,
  deps: [PLATFORM_ID],
})
export abstract class LocalStorageService implements Storage {
  [name: string]: unknown;
  abstract length: number;
  abstract clear(): void;
  abstract getItem(key: string): string | null;
  abstract key(index: number): string | null;
  abstract removeItem(key: string): void;
  abstract setItem(key: string, value: string): void;
}
