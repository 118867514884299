import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';

import { selectIsUserLoggedIn } from '../selectors/auth.selectors';

@Injectable({ providedIn: 'root' })
export class IsLoggedInGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!isPlatformBrowser(this.platformId as string)) {
      return true;
    }

    return this.store.select(selectIsUserLoggedIn).pipe(
      take(1),
      // eslint-disable-next-line @ngrx/avoid-mapping-selectors
      map((isUserLoggedIn) => {
        return (
          isUserLoggedIn ||
          this.router.createUrlTree(['/', 'auth', 'login'], {
            queryParams: { returnUrl: state.url },
          })
        );
      })
    );
  }
}
